import React, { useEffect } from 'react';
import logo from '../../logo.png';
import '../../App.css';
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../../contexts/AuthContext';



function Nav({dashboard, menu, home}) {
  const navigate = useNavigate()
  const {currentUser, signout ,changeLanguage,listProducts, language,currentRestaurant, languages} = useAuth()
  const user = localStorage.getItem('connected');
  const userLang = navigator.language || navigator.userLanguage; 
  const userLangCode = userLang.split('-')[0];
  const isUserLangInLanguages = languages.some(language => language.code === userLangCode);



  useEffect(() => { 
    currentRestaurant?.defaultL === "false" && changeLanguage("en") 
    currentRestaurant && isUserLangInLanguages && changeLanguage(userLangCode) 
    currentRestaurant && !isUserLangInLanguages && userLangCode !== "fr" && changeLanguage("en") 

      // eslint-disable-next-line react-hooks/exhaustive-deps
     }, [currentRestaurant]); 


  return (
<div className={` ${menu ? 'header2' : 'header'}`} >
<img  onClick={() => navigate(`/`)} className='logo'  src={logo} alt="Logo" />

{!menu && home && <p className='mobile-user' onClick={() => user ? navigate(`/dashboard`) : navigate(`/login`)  }>{user ? 'Dashboard' : 'Se connecter'}</p>}
{!menu && !home && <p className='mobile-user' onClick={signout}>Se déconnecter</p>}

{menu && listProducts && <div>
          <select
          className='select'
            id="language"
            value={language}
            onChange={(e) => changeLanguage(e.target.value)}
            
          >
  <option value="">Français</option>
 {languages?.map((language, index) => (
  <option key={index} value={language.code}>
    {language.origin}
  </option>
))}

          </select>
        </div>}



{!home && <div className='side-header'>
  {!dashboard && <span onClick={() => user ?  navigate(`/dashboard`) : navigate(`/login`)}>Dashboard</span>}
  <span onClick={signout}>Se déconnecter</span>
  <img className=' userlogo'  src={currentUser?.photoURL} alt="Logo" />
</div>}

{home && <div className=' side-header' >
  <span style={{marginRight:0}} onClick={() => user ?  navigate(`/dashboard`) : navigate(`/login`) }>{user ? 'Dashboard' : 'Se connecter'}</span>
</div>}
</div>
  )
}

export default Nav

/*hhjjjjj
kpokpokpo
, lk,klm,lk,
l,nlk,lk,
*/