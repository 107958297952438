import React, { useEffect, useState } from 'react'
import Nav from '../components/Nav/Nav'
import { ArrowLeftOutlined, LoadingOutlined } from '@ant-design/icons';
import { useParams } from "react-router-dom"
import { useAuth,  } from '../contexts/AuthContext'
import { useNavigate } from 'react-router-dom'
import '../App.css';

function Category() {
    const navigate = useNavigate()
    const {id} = useParams();
    const { getcategory ,currentCategory, updateCategory, isLoading,categoryM, deleteCategory,  t, translated, languages} = useAuth()
    const [name, setName] = useState('');
    const [selectedOption, setSelectedOption] = useState(''); 
    const [position, setPosition] = useState(0);
    const [checkboxState, setCheckboxState] = useState({});
    const currentUser = localStorage.getItem('connected');


    useEffect(() => { 
      getcategory(id)
     // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); 

       useEffect(() => { 
   if(currentCategory ) {
    setName(currentCategory.name)
      setSelectedOption(currentCategory.status)
      setPosition(currentCategory.position)
   }  
         }, [currentCategory]); 


         useEffect(() => { 
          !currentUser &&  window.history.back()
           }, [currentUser]);
  


         const handleSubmit = (event) => {
          event.preventDefault();
          updateCategory(id, name,currentCategory.restaurant, selectedOption, parseFloat(position), name !== currentCategory.name ? translated : currentCategory.translated);
        };

        const handleCheckboxChange = (code) => {
          setCheckboxState((prevState) => ({
            ...prevState,
            [code]: !prevState[code],
          }));
          t(name,code )
          };

  return (
    <div>
      <Nav></Nav>
      <form  onSubmit={handleSubmit} className='wrapper-container'>
      <div className='wrapper-content'>
      <div className='title-wrapper'>
      <div className='title-back'>
         <ArrowLeftOutlined onClick={() => navigate(-1)}  className='back'/>
            <p>{currentCategory && currentCategory.name}</p>
            </div>  
          </div>
      <div className='wrapper-form'>

        <div className='input-container'>
        <label htmlFor="name">Nom de la catégorie</label>
          <input
            type="text"
            id="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </div>

        <div className='input-container'>
        <label htmlFor="position">Position</label>
          <input
            type="number"
            id="position"
            value={position}
            onChange={(e) => setPosition(e.target.value)}
            required
          />
        </div>





        <div className='input-container'>
        <label htmlFor="selectOption">Status</label>
          <select
            id="selectOption"
            value={selectedOption}
            onChange={(e) => setSelectedOption(e.target.value)}
            required
          >
            <option value="visible">Visible</option>
            <option value="masqué">Masqué</option>
          </select>
        </div>


        {   name !== currentCategory?.name &&  <div  className='input-container'>
        <label htmlFor="checkbox">Traduction</label>

        <div className='checkbox-container'>


        {languages.map((language, index) => (
  <label key={index}>
    <input
      type="checkbox"
      checked={checkboxState[language.code] || false}
      onChange={() => handleCheckboxChange(language.code)}
    />
    {language.name}
  </label>
))}
        </div>

</div>}

       
       <div className='validation-container'>

       <button className='button save' type="submit">{ isLoading ? <LoadingOutlined  spin /> : 'Enregistrer'}
</button>

{ categoryM ?  <div className='delete info'>{categoryM}</div> :
 <div className='delete' onClick={() => deleteCategory(id)}>Supprimer</div> }

       </div>
       
      </div>
      </div>
       
      </form>
      </div>
  )
}

export default Category


