import React, { useState, useEffect } from 'react';
import Nav from '../components/Nav/Nav'
import { ArrowLeftOutlined, PlusOutlined, LoadingOutlined , DeleteOutlined} from '@ant-design/icons';
import { useParams } from "react-router-dom"
import { useNavigate } from 'react-router-dom'
import { useAuth, } from '../contexts/AuthContext';
import imageCompression from 'browser-image-compression';
import '../App.css';

function Product() {
  const { currentProduct, updateProduct, deleteProduct,
    isLoading, productM, listCategories, getCategories,
    photoUrl, getProduct, addPhoto, t, translated, languages , tOption,translated2, t2, translatedOpt} = useAuth()
  const navigate = useNavigate()
  const { id } = useParams();

  const [name, setName] = useState('');
  const [price, setPrice] = useState(0);
  const [description, setDescription] = useState('');
  const [category, setCategory] = useState('');
  const [photo, setPhoto] = useState('');
  const [status, setStatus] = useState('visible');
  const [imagePreview, setImagePreview] = useState('');
  const [checkboxState, setCheckboxState] = useState({});
  const currentUser = localStorage.getItem('connected');
  const [hide, setHide] = useState(false);
  const [checkboxStateOpt, setCheckboxStateOpt] = useState({});
  const [langSelected, setLangSelected] = useState([]);
  const [options, setOptions] = useState([]);
  const [allergens, setAllergens] = useState('');
  const [checkboxState2, setCheckboxState2] = useState({});
  const [label, setLabel] = useState('');





  useEffect(() => {
    getProduct(id)
    options.length < 1 && setHide(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => { 
    langSelected.length  === languages?.length && tOption(options, langSelected) && setLangSelected([])
  
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [langSelected]);


  useEffect(() => {
    currentProduct && getCategories(currentProduct.restaurant)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentProduct]);

  useEffect(() => {
    !currentUser && window.history.back()
  }, [currentUser]);



  useEffect(() => {
    console.log(photo)
  }, [photo]);


  useEffect(() => {
    if (currentProduct) {
      setName(currentProduct.name)
      setCategory(currentProduct.category)
      setPrice(currentProduct.price)
      setStatus(currentProduct.status)
      setPhoto(currentProduct.picture)
      setDescription(currentProduct.description)
      currentProduct.translatedOpt ?  setOptions(currentProduct.translatedOpt) : setOptions([])
      currentProduct.allergens ? setAllergens(currentProduct.allergens) : setAllergens("")
      currentProduct.label ? setLabel(currentProduct.label) : setLabel("")

    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentProduct]);


  useEffect(() => {

    photoUrl && updateProduct(id, name, price, description, category, photoUrl, status, currentProduct?.position, description !== currentProduct.description ? translated : currentProduct.translated, 
      currentProduct.translatedOpt ? (translatedOpt.length > 0 ? translatedOpt : currentProduct.translatedOpt) : translatedOpt, allergens !== currentProduct.allergens ? translated2 : currentProduct.translated2, allergens ,
      currentProduct.label ? ( currentProduct.label !== label ? label : currentProduct.label ) : label)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [photoUrl]);


  const handleSubmit = (event) => {
    event.preventDefault();
    if (photo !== currentProduct.picture) {
      addPhoto(photo)
    } else {
      updateProduct(id, name, price, description, category, photo, status, currentProduct?.position, description !== currentProduct.description ? translated : currentProduct.translated,
        currentProduct.translatedOpt ? (translatedOpt.length > 0 ? translatedOpt : currentProduct.translatedOpt) : translatedOpt,  allergens !== currentProduct.allergens ? translated2 : currentProduct.translated2, allergens ,
        currentProduct.label ? ( currentProduct.label !== label ? label : currentProduct.label ) : label)
      }
  };

  const handleCheckboxChange = (code) => {
    setCheckboxState((prevState) => ({
      ...prevState,
      [code]: !prevState[code],
    }));
    t(description, code)
  };

  const removeOption = (indexToRemove) => {
    setOptions(options.filter((_, index) => index !== indexToRemove));
  };


  const handleFileChange = (event) => {
    const imageFile = event.target.files[0];
    console.log('originalFile instanceof Blob', imageFile instanceof Blob); // true
    console.log(`originalFile size ${imageFile.size / 1024 / 1024} MB`);
  
    var options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 700,
      useWebWorker: true
    }
    imageCompression(imageFile, options)
    .then(function (compressedFile) {
      console.log('compressedFile instanceof Blob', compressedFile instanceof Blob); // true
      console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB

      setPhoto(compressedFile); // write your own logic
    })
    .catch(function (error) {
      console.log(error.message);
    });
    

    const reader = new FileReader();
    reader.onloadend = () => {
      setImagePreview(reader.result);
    };

    if (imageFile) {
      reader.readAsDataURL(imageFile);
    } else {
      setImagePreview('');
    }
  };

  const handleAddOption = () => {
    setOptions([...options, { name: '', price: '', type: '' }]);
    setCheckboxStateOpt({})
  };

  const handleOptionChange = (index, field, value) => {
    const updatedOptions = [...options];
    updatedOptions[index][field] = value;
    setOptions(updatedOptions);
  };


  const handleCheckboxChange2 = (code) => {
    setCheckboxState2((prevState) => ({
      ...prevState,
      [code]: !prevState[code],
    }));
    t2(allergens,code)
    };



  const handleCheckboxChangeOpt = (code, index) => {
    setCheckboxStateOpt((prevState) => ({
      ...prevState,
      [code]: !prevState[code],
    }));
    setLangSelected( [...langSelected, code])
  };


  return (
    <div>
      <Nav></Nav>
      <form onSubmit={handleSubmit} className='wrapper-container'>
        <div className='wrapper-content'>
          <div className='title-wrapper'>
            <div className='title-back'>
              <ArrowLeftOutlined onClick={() => navigate(-1)} className='back' />
              <p>{currentProduct && currentProduct.name}</p>
            </div>
          </div>
          <div className='wrapper-form'>

            <div className='input-container'>
              <label htmlFor="name">Nom du produit</label>
              <input
                type="text"
                id="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
            </div>


            <div className='input-container'>
              <label htmlFor="price">Prix</label>
              <input
                type="number"
                id="price"
                value={price}
                onChange={(e) => setPrice(e.target.value)}
                required
                placeholder='$'
              />
            </div>



            <div className='input-container'>
              <label htmlFor="category">Catégorie</label>
              <select
                id="category"
                value={category}
                onChange={(e) => setCategory(e.target.value)}
                required
              >
                {listCategories?.map((categorie, index) => (
                  <option key={index} value={categorie.id}>
                    {categorie.name}
                  </option>
                ))}
              </select>
            </div>




            <div className='input-container'>
              <label htmlFor="description">Description</label>
              <input
                type="text"
                id="description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </div>






            <div className='input-container' style={{ height: 'auto' }}>
              <label htmlFor="photo">Photo</label>

              {photo ? <div className='photo-container'>
                <div style={{ maxWidth: '100%', maxHeight: '110px', display: 'flex' }} >
                  <img src={imagePreview ? imagePreview : photo} alt="Selected" style={{ maxWidth: '100%', maxHeight: '110px' }} />
                  <input
                    className="inputfile"
                    type="file"
                    id="fileInput"
                    accept="image/*"
                    onChange={handleFileChange}
                  />
                </div>
              </div> :
                <div className='photo-container'>
                  <div className='photo'><PlusOutlined />
                    <input
                      className="inputfile"
                      type="file"
                      id="fileInput"
                      accept="image/*"
                      onChange={handleFileChange}
                    />
                  </div>

                </div>
              }
            </div>


            <div className='input-container'>
        <label htmlFor="allergen">Allèrgenes</label>
          <input
            type="text"
            id="allergen"
            value={allergens}
            onChange={(e) => setAllergens(e.target.value)}
          />
        </div>



        <div className='input-container'>
        <label htmlFor="label">Label</label>
          <select
            id="label"
            value={label}
            onChange={(e) => setLabel(e.target.value)}
          >
            <option value="">Choisir un label</option>
            <option value="vegetarien">Végétarien</option>
            <option value="halal">Halal</option>
          </select>
        </div>

            <div className='input-container'>
              <label htmlFor="status">Status</label>
              <select
                id="status"
                value={status}
                onChange={(e) => setStatus(e.target.value)}
                required
              >
                <option value="visible">Visible</option>
                <option value="hidden">Masqué</option>
              </select>
            </div>



            {description !== currentProduct?.description && <div className='input-container'>
              <label htmlFor="checkbox">Traduction</label>

              <div className='checkbox-container'>

                {languages.map((language, index) => (
                  <label key={index}>
                    <input
                      type="checkbox"
                      checked={checkboxState[language.code] || false}
                      onChange={() => handleCheckboxChange(language.code)}
                    />
                    {language.name}
                  </label>
                ))}
              </div>

            </div>}


            {  allergens && allergens !==  currentProduct?.allergens  &&  <div  className='input-container'>
        <label htmlFor="checkbox">Traduction</label>

        <div className='checkbox-container'>


        {languages.map((language, index) => (
  <label key={index}>
    <input
      type="checkbox"
      checked={checkboxState2[language.code] || false}
      onChange={() => {handleCheckboxChange2(language.code)} }
    />
    {language.name}
  </label>
))}
        </div>

</div>}




            { options.length > 0 && !hide && <div className='wrapper-modify-opt'>
              <div className='modify-opt-header'>
            <p>Options</p>
            <span onClick={() => setHide(true)}>Modifier</span> 
              </div>


            <div className='modal-option'>

              {options.map((item, index) => (
        <div key={index} className='modal-text-option modify-opt-text'>
          <p>{item.name }</p>
          <p>{`${item.price} €`}</p>
        </div>
      ))}

            </div>

          </div>}


          { hide && 
          <>
          <div className='validation-container'>
              <button className='button save more-option' type="button" onClick={handleAddOption}>Ajouter une option</button>
        </div>

        {options.map((option, index) => (
              <div key={index} className="option-wrapper">
                <div className='input-container'>
                  <label htmlFor={`option-name-${index}`}>{`option ${index + 1}`}</label>

                  <div className='option-container'>
                  <DeleteOutlined onClick={() => removeOption(index)}/>

                  <input
                    type="text"
                    className='input-option-name'
                    id={`option-name-${index}`}
                    value={option.name}
                    onChange={(e) => handleOptionChange(index, 'name', e.target.value)}
                    placeholder='Nom'

                  />

                  <input
                    type="number"
                    className='input-option-price'
                    id={`option-price-${index}`}
                    value={option.price}
                    onChange={(e) => handleOptionChange(index, 'price', e.target.value)}
                    placeholder='Prix'
                  />


                 <input
                    type="number"
                    className='input-option-price'
                    id={`option-type-${index}`}
                    value={option.type}
                    onChange={(e) => handleOptionChange(index, 'type', e.target.value)}
                    placeholder='Type'
                  />

                 </div>
                </div>
              </div>
            ))}
        </>
        }


{   options.length > 0  &&  hide && options !== currentProduct?.translatedOpt && <div  className='input-container'>
        <label htmlFor="checkbox">Traduction</label>

        <div className='checkbox-container'>


        {languages.map((language, index) => (
  <label key={index}>
    <input
      type="checkbox"
      checked={checkboxStateOpt[language.code] || false}
      onChange={() => handleCheckboxChangeOpt(language.code, index)}
    />
    {language.name}
  </label>
))}
        </div>

</div>}






            <div className='validation-container'>

              <button className='button save' type="submit">{isLoading ? <LoadingOutlined spin /> : 'Enregistrer'}
              </button>

              {productM ? <div className='delete info'>{productM}</div> :
                <div className='delete' onClick={() => deleteProduct(id)}>Supprimer</div>}
            </div>

          </div>
        </div>

      </form>
    </div>
  )
}

export default Product


