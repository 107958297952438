import React, { useState, useEffect } from 'react';
import Nav from '../components/Nav/Nav'
import {ArrowLeftOutlined  , PlusOutlined , LoadingOutlined} from '@ant-design/icons';
import { useParams } from "react-router-dom"
import { useNavigate } from 'react-router-dom'
import { useAuth,  } from '../contexts/AuthContext';
import imageCompression from 'browser-image-compression';
import '../App.css';


function NewProduct() {
  const { postProduct, isLoading,productM ,listCategories, languages
     ,getCategories, photoUrl, addPhoto, getProducts, listProducts, t,tOption, translated,translated2, t2, translatedOpt} = useAuth()
    const navigate = useNavigate()
    const {id} = useParams();

    const [name, setName] = useState('');
    const [price, setPrice] = useState('');
    const [description, setDescription] = useState('');
    const [allergens, setAllergens] = useState('');

    const [category, setCategory] = useState('');
    const [photo, setPhoto] = useState('');
    const [status, setStatus] = useState('visible');
    const [label, setLabel] = useState('');

    const [imagePreview, setImagePreview] = useState('');
    const MyCategories = listCategories?.filter(function(categorie) { return categorie.restaurant === id });
    const MyProducts = listProducts?.filter(function(product) { return product.category === category });
    const [checkboxState, setCheckboxState] = useState({});
    const [checkboxState2, setCheckboxState2] = useState({});

    const [checkboxStateOpt, setCheckboxStateOpt] = useState({});
    const [langSelected, setLangSelected] = useState([]);
    const [options, setOptions] = useState([]);

   

    useEffect(() => { 
      getProducts(id)
      getCategories(id)
  // eslint-disable-next-line react-hooks/exhaustive-deps
}, []);




useEffect(() => { 
  langSelected.length  === languages?.length && tOption(options, langSelected) && setLangSelected([])

// eslint-disable-next-line react-hooks/exhaustive-deps
}, [langSelected]);

       useEffect(() => { 

       if(productM){
         setName("")
        setDescription("")
        setAllergens("")
        setPrice('')
        setPhoto("")
        setImagePreview("")
        setCheckboxState({})
        setCheckboxState2({})
        setCheckboxStateOpt({})
        setLangSelected([])
        setOptions([])
        setLabel("")
       }
       
         }, [productM]);
   



       useEffect(() => {
       
      photoUrl && postProduct(name,price, description, category, photoUrl, status, MyProducts?.length + 1, id, translated, translatedOpt, translated2, allergens, label)
      
              // eslint-disable-next-line react-hooks/exhaustive-deps
       }, [photoUrl]);


    const handleSubmit = (event) => {
         event.preventDefault();
     photo ? addPhoto(photo) : postProduct(name,price, description, category, photoUrl, status, MyProducts?.length + 1, id, translated, translatedOpt, translated2, allergens, label)
     getProducts(id)
     getCategories(id)

    };



    const handleCheckboxChange = (code) => {
      setCheckboxState((prevState) => ({
        ...prevState,
        [code]: !prevState[code],
      }));
      t(description,code)
      };


      const handleCheckboxChange2 = (code) => {
        setCheckboxState2((prevState) => ({
          ...prevState,
          [code]: !prevState[code],
        }));
        t2(allergens,code)
        };
  





      const handleCheckboxChangeOpt = (code, index) => {
        setCheckboxStateOpt((prevState) => ({
          ...prevState,
          [code]: !prevState[code],
        }));
        setLangSelected( [...langSelected, code])
      };


    const handleFileChange = (event) => {
      const imageFile = event.target.files[0];
      console.log('originalFile instanceof Blob', imageFile instanceof Blob); // true
      console.log(`originalFile size ${imageFile.size / 1024 / 1024} MB`);
    
      var options = {
        maxSizeMB: 0.3,
        maxWidthOrHeight: 700,
        useWebWorker: true
      }
      imageCompression(imageFile, options)
      .then(function (compressedFile) {
        console.log('compressedFile instanceof Blob', compressedFile instanceof Blob); // true
        console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB
  
        setPhoto(compressedFile); // write your own logic
      })
      .catch(function (error) {
        console.log(error.message);
      });
      

      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
  
      if (imageFile) {
        reader.readAsDataURL(imageFile);
      } else {
        setImagePreview('');
      }
    };

    const handleAddOption = () => {
      setOptions([...options, { name: '', price: '' , type: ""}]);
      setCheckboxStateOpt({})
    };
  
    const handleOptionChange = (index, field, value) => {
      const updatedOptions = [...options];
      updatedOptions[index][field] = value;
      setOptions(updatedOptions);
    };
  

  return (
    <div>
      <Nav></Nav>
      <form onSubmit={handleSubmit} className='wrapper-container'>
      <div className='wrapper-content'>
      <div className='title-wrapper'>
      <div className='title-back'>
         <ArrowLeftOutlined onClick={() => navigate(-1)}  className='back'/>
            <p>Nouveau produit</p>
            </div>  
          </div>
      <div className='wrapper-form'>

      <div className='input-container'>
        <label htmlFor="name">Nom du produit</label>
          <input
            type="text"
            id="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </div>


        <div className='input-container'>
        <label htmlFor="price">Prix</label>
          <input
            type="number"
            id="price"
            value={price}
            onChange={(e) => setPrice(e.target.value)}
            required
            placeholder='$'
          />
        </div>



        <div className='input-container'>
        <label htmlFor="category">Catégorie</label>
          <select
            id="category"
            value={category}
            onChange={(e) => setCategory(e.target.value)}
            required
          >
<option value="">Choisir une catégorie</option>
{MyCategories?.map((categorie, index) => (
  <option key={index} value={categorie.id}>
    {categorie.name}
  </option>
))}
          </select>
        </div>




        <div className='input-container'>
        <label htmlFor="description">Description</label>
          <input
            type="text"
            id="description"
            value={description}
            onChange={(e) => {setDescription(e.target.value) ; e.target.value === '..' && setDescription(name)}}
          />
        </div>


       




        <div className='input-container' style={{height:'auto'}}>
    <label htmlFor="photo">Photo</label>

     

     {imagePreview ? (
          <div className='photo-container'>
            <div style={{ maxWidth: '100%', maxHeight: '110px', display:'flex' }} >
            <img src={imagePreview} alt="Selected" style={{ maxWidth: '100%', maxHeight: '110px' }} />
            <input
       className="inputfile"
            type="file"
            id="fileInput"
            accept="image/*"
            onChange={handleFileChange}
          />
          </div>
          </div>
        ) : (

          <div className='photo-container'>
       <div className='photo'><PlusOutlined />
              <input
       className="inputfile"
            type="file"
            id="fileInput"
            accept="image/*" 
            onChange={handleFileChange}
          />
       </div>
     </div>
        )
        }
        </div>


        <div className='input-container'>
        <label htmlFor="allergen">Allèrgenes</label>
          <input
            type="text"
            id="allergen"
            value={allergens}
            onChange={(e) => setAllergens(e.target.value)}
          />
        </div>


        <div className='input-container'>
        <label htmlFor="label">Label</label>
          <select
            id="label"
            value={label}
            onChange={(e) => setLabel(e.target.value)}
          >
            <option value="">Choisir un label</option>
            <option value="vegetarien">Végétarien</option>
            <option value="halal">Halal</option>
          </select>
        </div>


        <div className='input-container'>
        <label htmlFor="status">Status</label>
          <select
            id="status"
            value={status}
            onChange={(e) => setStatus(e.target.value)}
            required
          >
            <option value="visible">Visible</option>
            <option value="hidden">Masqué</option>
          </select>
        </div>


{   description &&  <div  className='input-container'>
        <label htmlFor="checkbox">Traduction</label>

        <div className='checkbox-container'>


        {languages.map((language, index) => (
  <label key={index}>
    <input
      type="checkbox"
      checked={checkboxState[language.code] || false}
      onChange={() => {handleCheckboxChange(language.code)} }
    />
    {language.name}
  </label>
))}
        </div>

</div>}


{   allergens &&  <div  className='input-container'>
        <label htmlFor="checkbox">Traduction</label>

        <div className='checkbox-container'>


        {languages.map((language, index) => (
  <label key={index}>
    <input
      type="checkbox"
      checked={checkboxState2[language.code] || false}
      onChange={() => {handleCheckboxChange2(language.code)} }
    />
    {language.name}
  </label>
))}
        </div>

</div>}



<div className='validation-container'>
              <button className='button save more-option' type="button" onClick={handleAddOption}>Ajouter une option</button>
            </div>

{options.map((option, index) => (
              <div key={index} className="option-wrapper">
                <div className='input-container'>
                  <label htmlFor={`option-name-${index}`}>{`option ${index + 1}`}</label>

                  <div className='option-container'>

                  <input
                    type="text"
                    className='input-option-name'
                    id={`option-name-${index}`}
                    value={option.name}
                    onChange={(e) => {handleOptionChange(index, 'name', e.target.value); e.target.value === '..' && handleOptionChange(index, 'name', name)}}
                    placeholder='Nom'

                  />

                  <input
                    type="number"
                    className='input-option-price'
                    id={`option-price-${index}`}
                    value={option.price}
                    onChange={(e) => handleOptionChange(index, 'price', e.target.value)}
                    placeholder='Prix'
                  />

                 <input
                    type="number"
                    className='input-option-price'
                    id={`option-type-${index}`}
                    value={option.type}
                    onChange={(e) => handleOptionChange(index, 'type', e.target.value)}
                    placeholder='Type'
                  />

                 </div>
                </div>
              </div>
            ))}

{   options.length > 0  && <div  className='input-container'>
        <label htmlFor="checkbox">Traduction</label>

        <div className='checkbox-container'>


        {languages.map((language, index) => (
  <label key={index}>
    <input
      type="checkbox"
      checked={checkboxStateOpt[language.code] || false}
      onChange={() => handleCheckboxChangeOpt(language.code, index)}
    />
    {language.name}
  </label>
))}
        </div>

</div>}


       
        <div className='validation-container'>

<button className='button save' type="submit">{ isLoading ? <LoadingOutlined  spin /> : 'Enregistrer'}
</button>
{ productM && <div className='delete info'>{productM}</div>}

</div>

       
      </div>
      </div>
       
      </form>

      <div>
    </div>
      </div>
  )
}

export default NewProduct


