import React from 'react'
import mob from '../mob.png';
import '../App.css';
import Nav from '../components/Nav/Nav'
import { TranslationOutlined, InstagramOutlined , QrcodeOutlined, SettingOutlined,SelectOutlined, StarOutlined, CustomerServiceOutlined } from '@ant-design/icons';


function Home() {
  return (
    <div>
      {window.innerWidth < 450 ? (<div >
        <div>
          <Nav home></Nav>
          <div style={{ paddingTop: 40, paddingLeft: "7%", paddingRight: "7%",paddingBottom:50, display: "block", justifyContent: "space-between", width: "100%" }}>

            <div>
              <p style={{ fontSize: 40, textAlign: 'left' }}>Votre menu digital en plusieurs langues</p>
            </div>
            <div style={{ marginTop: 18, fontSize: 16, lineHeight: 1.9, marginBottom: 30, textAlign: "left" }}>
              <p>Fastline simplifie la création de votre menu digital et permet la traduction automatique dans plusieurs langues. Attirez plus de clients et recueillez plus d'avis avec notre module de notation intégré. Faites de votre établissement une expérience multilingue et agrandissez votre clientèle avec Fastline.</p>
            </div>
            <div style={{
              height: 50, display: 'flex', color: 'white', alignItems: 'center', justifyContent: 'center', width: 170, fontSize: 16,
              paddingRight: 10, paddingLeft: 10, borderRadius: 100, backgroundColor: '#000', marginTop: 20, cursor: 'pointer'
            }}><a style={{ color: 'white' }} rel="noreferrer" target="_blank" href="mailto:contact@fastlineapp.com">Nous contacter</a></div>

            <div style={{ display: "flex", textAlign: "center", marginTop: 85, justifyContent: 'center' }}>

              <img src={mob} width="auto" height="499" alt=""></img>

            </div>
              <p style={{textAlign:'center', color:'#717171', fontSize:14, marginTop:35}}>
                <a rel="noreferrer" style={{textDecoration:'underline'}} target="_blank" href="https://fastlineapp.com/restaurant/6oFw9at4B8N9L3U9HwMf/menu">Découvrir la démo</a> <span><SelectOutlined rotate={90}/></span>
                </p>




            <div style={{ marginTop: 85, lineHeight: 1.4 }}>
              <div style={{ marginTop: 0 }}>
                <TranslationOutlined style={{ fontSize: 30 }} />
                <p style={{ marginTop: 10, fontWeight: 'bold' }}>Flexibilité linguistique</p>
                <p style={{ marginTop: 5, color:'#717171' }}>Votre menu est disponible dans 10 langues, ce qui facilite la compréhension et améliore l'expérience des clients internationaux.</p>
              </div>


              <div style={{ marginTop: 40 }}>
                <StarOutlined style={{ fontSize: 30 }} />
                <p style={{ marginTop: 10, fontWeight: 'bold' }}>Augmentation des avis</p>
                <p style={{ marginTop: 5, color:'#717171' }}>Collectez davantage d’avis clients pour renforcer votre visibilité et améliorer votre réputation sur les moteurs de recherche.</p>
              </div>

               <div style={{ marginTop: 40}}>
                <InstagramOutlined style={{ fontSize: 30 }} />
                <p style={{ marginTop: 10, fontWeight: 'bold' }}>Intégration des réseaux sociaux</p>
                <p style={{ marginTop: 5 , color:'#717171'}}>Augmentez votre visibilité en connectant vos réseaux sociaux directement sur votre page Fastline. Permettez à vos clients de suivre vos actualités et offres spéciales.</p>
              </div>


              <div style={{ marginTop: 40 }}>
                <QrcodeOutlined style={{ fontSize: 30 }} />
                <p style={{ marginTop: 10, fontWeight: 'bold' }}>QR codes personnalisés</p>
                <p style={{ marginTop: 5 , color:'#717171'}}>Facilitez l'accès à votre menu digital avec des stickers QR code personnalisés, à disposer sur vos tables ou menus physiques pour une consultation rapide.</p>
              </div>


              <div style={{ marginTop: 40 }}>
                <SettingOutlined style={{ fontSize: 30 }} />
                <p style={{ marginTop: 10, fontWeight: 'bold' }}>Mises à jour instantanées</p>
                <p style={{ marginTop: 5, color:'#717171' }}>Modifiez votre menu en temps réel directement depuis votre espace client pour refléter les changements de plats ou les promotions, sans avoir à réimprimer vos menus.</p>
              </div>




              <div style={{ marginTop: 40 }}>
                <CustomerServiceOutlined style={{ fontSize: 30 }} />
                <p style={{ marginTop: 10, fontWeight: 'bold' }}>Assistance 24/7</p>
                <p style={{ marginTop: 5, color:'#717171' }}>Nous créons et configurons votre page Fastline avec votre menu initial. Vous bénéficiez d’une assistance continue pour personnaliser et gérer facilement votre espace client.</p>
              </div>


            </div>


           



            <div style={{ width: '100%', height:'auto',marginTop:90,  padding: 20, borderRadius:10, boxShadow: '0px 10px 21px 0px rgba(0,0,0,0.16)'}}>
              
              <h3 style={{textAlign:'center'}}>Tarif unique</h3>

                 <p style={{fontSize:31, textAlign:'center', marginTop:30}}>30 €</p>
                <p style={{fontSize:15, textAlign:'center', color:'#717171'}}>/mois HT</p>


                <p style={{textAlign:'center', marginTop:50}}>Sans engagement</p>
                <p style={{marginTop:15, textAlign:'center'}}>7 jours pour être équipé</p>
              <p style={{marginTop:15, textAlign:'center'}}>1 mois d'essai offert</p>


              <div style={{height:45, borderRadius:4,width:'100%' , marginTop:70
              , backgroundColor:'#111', color:'white' , display:'flex', alignItems:'center', justifyContent:'center', fontSize:14}}>
<a style={{color:'white'}} rel="noreferrer" target="_blank" href="https://wa.me/message/NR5ALYUJYWENM1">Démarrer l'essai gratuit</a>
              </div>

            
          </div>

          

          </div>


         


          <div>

          </div>

        </div>

      </div>) :
        <div style={{ width: "100%" }}>
          <Nav home></Nav>
          <div style={{
            paddingTop: 130, display: "flex", minHeight:'100vh',
            justifyContent: "space-between", paddingLeft: "7%", paddingRight: "7%"
          }}>
            <div style={{ maxWidth: '60%' }}>
              <div style={{ marginTop: 90 }}>
                <p style={{ fontSize: 60 }}>Votre menu digital en plusieurs langues</p>
              </div>
              <div style={{ marginTop: 25, fontSize: 18, maxWidth: 600 }}>
                <p style={{ fontSize: 16, lineHeight: 1.8 }}>
                  Fastline simplifie la création de votre menu digital et permet la traduction automatique dans plusieurs langues. Attirez plus de clients et recueillez plus d'avis avec notre module de notation intégré. Faites de votre établissement une expérience multilingue et agrandissez votre clientèle avec Fastline.
                </p>
                <div style={{
                  height: 50, display: 'flex', color: 'white', alignItems: 'center', justifyContent: 'center', width: 170, fontSize: 16,
                  paddingRight: 10, paddingLeft: 10, borderRadius: 100, backgroundColor: '#000', marginTop: 30, cursor: 'pointer'
                }}><a style={{ color: 'white' }} target="_blank" rel="noreferrer" href="mailto:contact@fastlineapp.com">Nous contacter</a>   </div>

                
              </div>

              <p style={{ color:'#717171', marginTop:30}}>
                <a rel="noreferrer" style={{textDecoration:'underline'}} target="_blank" href="https://fastlineapp.com/restaurant/6oFw9at4B8N9L3U9HwMf/menu">Découvrir la démo</a> <span><SelectOutlined rotate={90}/></span>
                </p>
              <div style={{ marginTop: 50, maxWidth: 700, }}>

              </div>
            </div>


            <div>
              <img src={mob} width="auto" height="580" alt=""></img>

            </div>

            


          </div>

          <div style={{ display:'flex', width:'100%', paddingRight:'7%', paddingLeft:'7%', justifyContent:'space-between'}}>
       
          <div style={{ display:'flex', maxWidth:'65%', flexWrap:'wrap' , lineHeight: 1.6 }}>
              <div style={{ marginTop: 50,width:'40%', marginRight:50}}>
                <TranslationOutlined style={{ fontSize: 30 }} />
                <p style={{ marginTop: 10, fontWeight: 'bold' }}>Flexibilité linguistique</p>
                <p style={{ marginTop: 5, color:'#717171' }}>Votre menu est disponible dans 10 langues, ce qui facilite la compréhension et améliore l'expérience des clients internationaux.</p>
              </div>


              <div style={{ marginTop: 50 ,width:'40%', marginRight:50 , lineHeight: 1.8}}>
                <StarOutlined style={{ fontSize: 30 }} />
                <p style={{ marginTop: 10, fontWeight: 'bold' }}>Augmentation des avis</p>
                <p style={{ marginTop: 5, color:'#717171' }}>Collectez davantage d’avis clients pour renforcer votre visibilité et améliorer votre réputation sur les moteurs de recherche.</p>
              </div>

              <div style={{ marginTop: 50 ,width:'40%', marginRight:50 , lineHeight: 1.8}}>
                <InstagramOutlined style={{ fontSize: 30 }} />
                <p style={{ marginTop: 10, fontWeight: 'bold' }}>Intégration des réseaux sociaux</p>
                <p style={{ marginTop: 5 , color:'#717171'}}>Augmentez votre visibilité en connectant vos réseaux sociaux directement sur votre page Fastline. Permettez à vos clients de suivre vos actualités et offres spéciales.</p>
              </div>

              <div style={{ marginTop: 50 ,width:'40%', marginRight:50 , lineHeight: 1.8}}>
                <QrcodeOutlined style={{ fontSize: 30 }} />
                <p style={{ marginTop: 10, fontWeight: 'bold' }}>QR codes personnalisés</p>
                <p style={{ marginTop: 5 , color:'#717171'}}>Facilitez l'accès à votre menu digital avec des stickers QR code personnalisés, à disposer sur vos tables ou menus physiques pour une consultation rapide.</p>
              </div>

             


              <div style={{ marginTop: 50 ,width:'40%', marginRight:50 , lineHeight: 1.8}}>
                <SettingOutlined style={{ fontSize: 30 }} />
                <p style={{ marginTop: 10, fontWeight: 'bold' }}>Mises à jour instantanées</p>
                <p style={{ marginTop: 5, color:'#717171' }}>Modifiez votre menu en temps réel directement depuis votre espace client pour refléter les changements de plats ou les promotions, sans avoir à réimprimer vos menus.</p>
              </div>




              <div style={{ marginTop: 50 ,width:'40%', marginRight:50 , lineHeight: 1.8}}>
                <CustomerServiceOutlined style={{ fontSize: 30 }} />
                <p style={{ marginTop: 10, fontWeight: 'bold' }}>Assistance 24/7</p>
                <p style={{ marginTop: 5, color:'#717171' }}>Nous créons et configurons votre page Fastline avec votre menu initial. Vous bénéficiez d’une assistance continue pour personnaliser et gérer facilement votre espace client.</p>
              </div>

            </div>


            <div style={{width: '100%', height:'auto',maxWidth:400, display:'flow', alignContent:'center'}}>

            <div style={{ width: '100%', height:'auto',marginTop:40, backgroundColor:'black', color:'white', padding: 20, borderRadius:10}}>
              
              <h3 style={{textAlign:'center'}}>Tarif unique</h3>

                 <p style={{fontSize:31, textAlign:'center', marginTop:30}}>30 €</p>
                <p style={{fontSize:15, textAlign:'center'}}>/mois HT</p>


                <p style={{textAlign:'center', marginTop:50}}>Sans engagement</p>
                <p style={{marginTop:15, textAlign:'center'}}>7 jours pour être équipé</p>
              <p style={{marginTop:15, textAlign:'center'}}>1 mois d'essai offert</p>


              <div style={{height:45, borderRadius:4,width:'100%' , marginTop:70, borderColor:'white', borderWidth:1
              , backgroundColor:'white', color:'black' , display:'flex', alignItems:'center', justifyContent:'center', fontSize:14}}>
<a style={{color:'black', fontWeight:'bold'}} rel="noreferrer" target="_blank" href="mailto:contact@fastlineapp.com">Démarrer l'essai gratuit</a>
              </div>

            
          </div>
          </div>

          </div>

          </div>


        }
      {window.innerWidth < 450 ? (<footer style={{ paddingBottom: 25, paddingTop: 60, textAlign: "center", width: "100%", fontSize: 12 }}>

        <p >©2024 Fastline.</p>
      </footer>) : <footer style={{  paddingBottom: 25, paddingTop: 120, textAlign: "center", width: "100%", fontSize: 15 }}>

        <p >©2024 Fastline.</p>
      </footer>}
    </div>

  )
}

export default Home