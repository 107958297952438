
import { initializeApp } from "firebase/app";
import {getFirestore} from 'firebase/firestore';
import { getAnalytics } from "firebase/analytics";
import {GoogleAuthProvider, getAuth} from 'firebase/auth';
import {getStorage} from 'firebase/storage';


const firebaseConfig = {
  apiKey: "AIzaSyBnwx9MmjIPjmC51sx0BDok5iauOsEEedQ",
  authDomain: "fastline-2cd49.firebaseapp.com",
  projectId: "fastline-2cd49",
  storageBucket: "fastline-2cd49.appspot.com",
  messagingSenderId: "444011835273",
  appId: "1:444011835273:web:796823700741213fa8fb58",
  measurementId: "G-C5DL8HC3QF"
};


const app = initializeApp(firebaseConfig);

export const analytics = getAnalytics(app);

export const auth = getAuth(app);

export const provider = new  GoogleAuthProvider();

export const db  = getFirestore(app);

export const storage  = getStorage(app);
