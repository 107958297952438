import React, { useEffect } from 'react'
import Nav from '../components/Nav/Nav'
import '../App.css';
import { useParams } from "react-router-dom"
import { useNavigate } from 'react-router-dom'
import { RightOutlined } from '@ant-design/icons';
import { useAuth, } from '../contexts/AuthContext'

function Restaurant() {
  const navigate = useNavigate()
  const { id } = useParams();
  const user = localStorage.getItem('connected');
  const { getRestaurant, currentRestaurant, currentUser , isLoading} = useAuth()

  useEffect(() => {
    getRestaurant(id)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
     !isLoading && user && currentRestaurant &&  currentRestaurant.owner !== currentUser.uid && navigate("/")
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [isLoading, user, currentRestaurant]);

  return (
    <div>
      <Nav></Nav>
      <div className='wrapper-container'>
{   currentRestaurant &&     <div className='wrapper-content'>
          <div className='title-wrapper'>
            <div>
              <p>{currentRestaurant.name}</p>
              <p className='grey'>{currentRestaurant.adress}</p>
            </div>
            <button className='button' onClick={()=>window.open(`/restaurant/${id}/menu`,'_blank', 'rel=noopener noreferrer')}>Voir le menu</button>
          </div>
          <div className='wrapper' style={{ padding: 0 }}>
            <div className='card-item restaurant'  onClick={() => navigate(`/restaurant/${id}/products`)}>
              <p >Produits</p>
              <RightOutlined className='link2' />
            </div>

            <div className='card-item restaurant' onClick={() => navigate(`/restaurant/${id}/categories`)}>
              <p >Catégories</p>
              <RightOutlined className='link2' />
            </div>

            <div className='card-item restaurant' onClick={() => navigate(`/restaurant/${id}/informations`)}>
              <p >Informations</p>
              <RightOutlined className='link2' />
            </div>


          </div>
        </div>}

      </div>
    </div>
  )
}

export default Restaurant