import React from 'react'
import '../App.css';
import logo from '../logo.png';
import logog from '../logog.png';
import {  LoadingOutlined} from '@ant-design/icons';
import { useAuth  } from '../contexts/AuthContext'


function Login() {
    const {signup,isLoading} = useAuth()

  return (
    <div className='wrapper-container-login'>
        <div className='wrapper-login'>
            <div className='logo-container'>

                        <img className='logo-login'  src={logo} alt="" />

            </div>

            <div className='header-login'>
                <p className='welcome'>Bienvenue</p>
            </div>
            <div className='header-login'>
                <p className='text'>Connectez-vous à Fastline, pour acceder à votre espace client.</p>
            </div>
            
            <div className='button-container'>
               
              <div className='google-button' onClick={() => !isLoading && signup()}>
             
            {isLoading ? <div className='google'></div> : <img className='google'  src={logog} alt="Logo" />}
             { isLoading ? <LoadingOutlined  spin /> : <p>Se connecter avec Google</p>}
            <div className='google'></div>
               
                </div>  

            </div>


         </div>
        
    </div>
  )
}

export default Login