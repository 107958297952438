import React from 'react'
import '../../App.css';
import { useSpring, animated } from '@react-spring/web'
import { useAuth, } from '../../contexts/AuthContext'
import vegi from '../../vegi.png'
import halal from '../../halal.png'



function ProductModal({ setIsOpen, name, price, description, photo , translatedOpt, allergens ,label}) {
  const { language } = useAuth()


  const trans = [{
      en: "Close",
      es: "Cerrar",
      it: "Chiudere",
      pt: "Fechar",
      de: "Schließen",
      nl: "Sluiten",
      zh: "关闭",
      ar: "إغلاق"

  },
  {
    en: "Supplements",
    es: "Suplementos",
    it: "Supplementi",
    pt: "Suplementos",
    de: "Ergänzungen",
    nl: "Supplementen",
    zh: "补助金",
    ar: "المكملات الغذائية"
},
{
  en: "Options",
  es: "Opciones",
  it: "Opzioni",
  pt: "Opções",
  de: "Optionen",
  nl: "Opties",
  zh: "选项",
  ar: "الخيارات"
},

{
  en: "Free",
  es: "Gratis",
  it: "Gratuito",
  pt: "Grátis",
  de: "Kostenlos",
  nl: "Gratis",
  zh: "免费",
  ar: "مجاناً"
}
]

  const springs = useSpring({
    from: { y: 600 },
    to: { y: 0 },
  })
  return (
    <>
      <div className='product-modal-container' onClick={() => setIsOpen(false)}>

        <animated.div className={`product-modal-content ${translatedOpt?.length > 0 && photo && 'modal-options'}`} style={{ ...springs, }}>
          <div>
            <img className={`product-modal-img ${!photo && 'hide-img-modal'}`}   alt='' src={photo} />
          </div>
          <div className='modal-text-container'>
            <div className='modal-header-container'>
                <p className='modal-text-header'>{name} {label && <span><img className='modal-label'  src={label === "halal" ? halal : vegi} alt="Logo" />
</span>}</p>

              {translatedOpt?.length === 0  && <p className='modal-text-price'>{parseFloat(price).toFixed(2)} €</p>}
              {!translatedOpt  && <p className='modal-text-price'>{parseFloat(price).toFixed(2)} €</p>}

                       {translatedOpt?.length > 0 && translatedOpt.length && translatedOpt[0].type === '2'  && <p className='modal-text-price'>{parseFloat(price).toFixed(2)} €</p>}
                        {translatedOpt?.length > 0 &&  translatedOpt[0].type === '1' && <p className='modal-text-price'></p>}
            </div>
           {name === description && language && <p className='modal-text-description'>{description}</p>}
           {name !== description && <p className='modal-text-description'>{description}</p>}

          </div>
        
                    { allergens && <div className='allergen modal-allergen'><p>{allergens}</p></div>}

        

         { translatedOpt && <div className='modal-text-container'>
           {translatedOpt[0]?.type === '2' && <p className='modal-text-sub-header'>{language ? trans[1][language] : "Suppléments"}</p>}
           {translatedOpt[0]?.type === '1' && <p className='modal-text-sub-header'>{language ? trans[2][language] : "Options"}</p>}

            <div className='modal-option'>

              {translatedOpt.map((item, index) => (
        <div key={index} className='modal-text-option'>
          <p className='modal-max-width'>{language ? (item.translations !== undefined ? item.translations[language]:  item.name ) :item.name }</p>
          <p className='modal-max-width'>{item.price < 0.1 ? (language ? trans[3][language] : "Gratuit") : `${item.type === '2' ? "+" : ''}${parseFloat(item.price).toFixed(2)} €` }</p>
        </div>
      ))}

            </div>

          </div>}


          <div onClick={() => setIsOpen(false)} className='modal-close-container'>
            <div className='modal-close-button'><p>{language ? trans[0][language] : "Fermer"}</p></div>
          </div>

        </animated.div>
      </div>
    </>
  )
}

export default ProductModal